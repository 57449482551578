const slider = new Swiper('.slider', {
  effect: 'fade',
  loop: true,
  speed: 3000,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
});

const pickupSwiper = new Swiper('.pickup-swiper', {
  loop: true,
  slidesPerView: 1.25,
  centeredSlides: true,
  spaceBetween: 12,
  navigation: {
    nextEl: '.pickup-next',
    prevEl: '.pickup-prev',
  },
  pagination: {
    el: '.pickup-pagination',
  },
  speed: 1000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  breakpoints: {
    // スライドの表示枚数：500px以上の場合
    992: {
      slidesPerView: 2.25,
      spaceBetween: 24,
    },
  },
});

$(function () {
  var targetTop = document.getElementById('infomation').offsetTop;
  var postion = targetTop - 134;
  $('.construct-header').addClass('clear');
  $(window).scroll(function () {
    var scroll = $(this).scrollTop();
    if (scroll > postion) {
      $('.construct-header').removeClass('clear');
    } else {
      $('.construct-header').addClass('clear');
    }
  });
});
